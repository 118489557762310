<template>
  <b-col class="">
    <!-- Row Loop -->

    <b-row
      v-for="(question, index) in questions"
      :id="question.id"
      :key="question.id"
      ref="row"
    >
      <b-card
        bg-variant="gray-100"
        border-variant="primary"
        text-variant="white"
        class="m-2"
      >
        <b-card-code>
          <addQuestionComponent
            :index="index"
            :question="question"
            :questions="questions"
          />
        </b-card-code>
      </b-card>
    </b-row>
    <!-- <b-row>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        @click="repeateAgain"
        class="mr-25"
      >
        <feather-icon icon="PlusIcon" class="mr-25" />
        <span>Add Question </span>
      </b-button>
    </b-row> -->
  </b-col>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BButton,
} from "bootstrap-vue";
import questionssApi from "@/apiServices/questionsApi";
import addQuestionComponent from "@/views/questions pages/components/add.vue";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, max_value, numeric } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    addQuestionComponent,
  },
  directives: {
    Ripple,
  },
  props: ["questions"],
  mixins: [heightTransition],
  data() {
    return {
      required,
      email,
      max_value,
      numeric,
      question: {
        title: "",
        description: "",
        type: "",
        imageCover: null,
        answers: [],
        grad: 0,
      },

      nextTodoId: 2,
    };
  },
  mounted() {},
  created() {},
  destroyed() {},
  methods: {
    repeateAgain() {
      this.questions.push({
        title: "",
        description: "",
        type: "",
        imageCover: null,
        answers: [],
        grad: 0,
      });
      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
</style>
