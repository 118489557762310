<template>
  <b-col class="mt-50">
    <!-- Row Loop -->
    <b-card
      bg-variant="light"
      v-for="(Answer, index) in answers"
      class="text-center"
    >
      <b-row>
        <h4>{{ alphabet[index] }} -</h4>

        <b-col md="3">
          <b-form-group label="title" label-for="name">
            <validation-provider
              #default="{ errors }"
              name="title"
              rules="required|min:3"
            >
              <b-form-input
                id="title"
                v-model="Answer.title"
                v-on:keyup.enter="createAnswers"
                :state="errors.length > 0 ? false : null"
                placeholder="Answer Tittle"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group label="Answer Image" label-for="CorseCoverImage">
            <b-form-file
              :id="index + 'answerimage'"
              accept="image/*"
              v-model="Answer.imageCover"
            />
          </b-form-group>
        </b-col>
        <b-col md="1">
          <b-form-group label="Is Correct" :label-for="'isCorrect' + index">
            <b-form-checkbox
              v-model="answers[index].isCorrect"
              :id="'isCorrect' + index + answers[index]._id"
              class="custom-control-success"
              name="check-button"
              switch
            />
          </b-form-group>
        </b-col>
        <b-col md="m-1 mt-1">
          <b-button
            v-b-toggle="`collapse-1-hint${index + answers[index]._id}`"
            variant="success"
            >add hint</b-button
          >
        </b-col>
        <b-col lg="2" md="" class="mb-50">
          <b-row>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="m-1"
              @click="updateanswerImage(Answer)"
            >
              <feather-icon icon="EditIcon" class="mr-25" />
            </b-button>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-danger"
              class="m-1"
              @click="removeItem(index)"
            >
              <feather-icon icon="XIcon" class="mr-25" />
            </b-button>
          </b-row>
        </b-col>

        <b-col md="12">
          <b-collapse :id="`collapse-1-hint${index + answers[index]._id}`">
            <b-card>
              <b-form-group label="Hint" label-for="Hint">
                <validation-provider #default="{ errors }" name="Hint">
                  <b-form-textarea
                    id="Hint"
                    placeholder="Hint"
                    v-model="Answer.hint"
                    :state="errors.length > 0 ? false : null"
                    rows="1"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-card>
          </b-collapse>
        </b-col>
        <br />
      </b-row>
    </b-card>

    <b-form-group label="title" label-for="name">
      <validation-provider #default="{ errors }" name="title">
        <b-form-input
          id="title"
          v-model="Answer.title"
          v-on:keyup.enter="createAnswers"
          :state="errors.length > 0 ? false : null"
          placeholder="Answer Tittle"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
  </b-col>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BButton,
} from "bootstrap-vue";
import answersApi from "@/apiServices/answersApi";
import addAnserComponent from "@/views/answers pages/components/addAnswerComponent.vue";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, max_value, numeric } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    addAnserComponent,
  },
  directives: {
    Ripple,
  },
  props: ["answers"],
  mixins: [heightTransition],
  data() {
    return {
      alphabet: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ],
      required,
      email,
      max_value,
      numeric,
      Answer: {
        title: "",
        hint: "",
        isCorrect: false,
        imageCover: null,
      },
    };
  },
  mounted() {},
  created() {
    // this.answers.push({
    //   title: "",
    //   hint: "",
    //   isCorrect: false,
    //   imageCover: null,
    // });
  },
  destroyed() {},
  methods: {
    onEnter: function () {
      this.answers.push({
        title: "",
        hint: "",
        isCorrect: false,
        imageCover: null,
      });
    },
    createAnswers() {
      answersApi.createAnswer(this.Answer).then((response) => {
        if (response.data) {
          console.log(response.data);
          this.answers.push(response.data?.data);
          this.answer = response.data?.data;
          this.Answer.title = "";
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Answer Created ",
              icon: "EditIcon",
              variant: "success",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      });
    },
    updateanswer(answer) {
      answersApi.updateanswer(answer).then((response) => {
        if (response.data) {
          // this.course = response.data;
          // console.log(response.data);
          this.$emit("updateanswerFromlist", this.id);

          this.$toast({
            component: ToastificationContent,
            props: {
              title: "answer updated",
              icon: "EditIcon",
              variant: "success",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      });
    },
    updateanswerImage(answer) {
      if (answer.imageCover instanceof File) {
        const imageFormData = new FormData();
        imageFormData.append("image", answer.imageCover);
        imagesApi.uploadImage(imageFormData).then((response) => {
          console.log(response);
          answer.imageCover = response;
          this.updateanswer(answer);
        });
      } else {
        delete answer.imageCover;
        this.updateanswer(answer);
      }
    },
    removeItem(index) {
      this.answers.splice(index, 1);
    },
    repeateAgain() {
      this.answers.push({
        title: "",
        hint: "",
        isCorrect: false,
        imageCover: null,
      });
      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
</style>
