<template>
  <b-card-code>
    <form ref="form" @submit.stop.prevent="handleSubmit">
      <validation-observer ref="questionInfoRules" tag="form">
        <b-row>
          <b-col cols="12" class="mb-2">
            <h5 class="mb-0">{{ index + 1 }} - {{ question.title }}</h5>
          </b-col>
          <b-col md="5">
            <b-form-group label="title" label-for="name">
              <validation-provider
                #default="{ errors }"
                name="title"
                rules="required|min:3"
              >
                <b-form-input
                  id="title"
                  v-model="question.title"
                  :state="errors.length > 0 ? false : null"
                  placeholder="question title"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group label="question type" label-for="questiontype">
              <validation-provider
                #default="{ errors }"
                name="question type"
                rules="required"
              >
                <b-form-select
                  id="questiontype"
                  :state="errors.length > 0 ? false : null"
                  v-model="question.type"
                  :options="options"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group label="grad" label-for="grad">
              <validation-provider
                #default="{ errors }"
                name="grad"
                rules="required|numeric"
              >
                <b-form-input
                  id="title"
                  v-model="question.grad"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              label="question Cover Image"
              label-for="CorseCoverImage"
            >
              <validation-provider #default="{ errors }" name="CorseCoverImage">
                <b-form-file
                  :id="'CorseCoverImage' + index"
                  accept="image/*"
                  v-model="question.imageCover"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group label="hint" label-for="hint">
              <validation-provider #default="{ errors }" name="hint">
                <b-form-textarea
                  id="hint"
                  placeholder="hint"
                  v-model="question.hint"
                  :state="errors.length > 0 ? false : null"
                  rows="1"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <addAnswerSection :answers="question.answers" />
          </b-col>
          <b-col md="12">
            <b-row variant="info">
              <b-col md="4"> </b-col>
              <b-col md="3">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  class=""
                  variant="outline-danger"
                  block
                  @click="removeItem(index)"
                >
                  delete
                </b-button>
              </b-col>
              <b-col md="3">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  class=""
                  variant="outline-info"
                  block
                  @click="updatequestion"
                >
                  <feather-icon icon="Edit2Icon" class="mr-50" />
                  <span class="mr-50 text-info">update</span>
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </validation-observer>
    </form>
  </b-card-code>
</template>

<script>
import {
  BButton,
  BFormGroup,
  BFormInput,
  BModal,
  VBModal,
  BListGroup,
  BListGroupItem,
  BRow,
  BCol,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import questionsApi from "@/apiServices/questionsApi";
import { required, email, max_value, numeric } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import addAnswerSection from "@/views/answers pages/components/add answers section new.vue";
import imagesApi from "@/apiServices/imagesApi";

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BListGroup,
    BListGroupItem,
    BModal,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    addAnswerSection,
  },
  directives: {
    Ripple,
  },
  props: ["question", "questions", "index"],
  data: () => ({
    required,
    email,
    max_value,
    numeric,
    name: "",
    nameState: null,
    options: ["single_choice", "multi_choice", "true_false"],
    submittedNames: [],
    question: {
      title: "",
      description: "",
      type: "",
      imageCover: null,
      answers: [],
      grad: 0,
    },
  }),
  destroyed() {
    this.questions = [];
  },
  methods: {
    createquestions(questionFormData) {
      questionsApi.createquestion(questionFormData).then((response) => {
        if (response.data) {
          //   this.question = response.data;
          this.questions[this.index] = response.data?.data;
          this.question = response.data?.data;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "question Created complite adding",
              icon: "EditIcon",
              variant: "success",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      });
    },
    updatequestion() {
      questionsApi.updatequestion(this.question).then((response) => {
        if (response.data) {
          // this.course = response.data;
          // console.log(response.data);

          this.$toast({
            component: ToastificationContent,
            props: {
              title: "question updated",
              icon: "EditIcon",
              variant: "success",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      });
    },
    createquestionValidation() {
      return new Promise((resolve, reject) => {
        this.$refs.questionInfoRules.validate().then((success) => {
          if (success) {
            const questionFormData = new FormData();
            // this.question.answers.forEach((item) => {
            //   item = item._id;
            // });
            this.question.answers = this.question.answers.map((a) => a._id);
            this.createquestions(this.question);

            this.question = {};
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    resetModal() {
      this.name = "";
      this.nameState = null;
    },
    removeItem(index) {
      this.questions?.splice(index, 1);
      // this.trTrimHeight(this.$refs.row[0].offsetHeight);
    },
    handleOk(bvModalEvt) {
      if (this.question.imageCover instanceof File) {
        const imageFormData = new FormData();
        imageFormData.append("image", this.question.imageCover);
        imagesApi.uploadImage(imageFormData).then((response) => {
          console.log(response);
          this.question.imageCover = response;
          this.createquestionValidation();
        });
      } else {
        delete this.question.imageCover;
        this.createquestionValidation();
      }
    },
    removeItem(index) {
      this.questions?.splice(index, 1);
      // this.trTrimHeight(this.$refs.row[0].offsetHeight);
    },
    handleSubmit() {
      // Exit when the form isn' t valid
      if (!this.checkFormValidity()) {
        return;
      }
      // Push the name to submitted names
      this.submittedNames.push(this.name);
      // Hide the modal manually
      this.$nextTick(() => {
        this.$refs["my-modal"].toggle("#toggle-btn");
      });
    },
  },
};
</script>
