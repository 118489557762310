<template>
  <div>
    <!-- button -->
    <b-button
      id="toggle-btn"
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      v-b-modal.modal-quistion-add
      variant="outline-primary"
    >
      +
    </b-button>

    <!-- modal data -->

    <!-- modal -->
    <b-modal
      id="modal-quistion-add"
      ref="modal-quistion-add"
      title="Add new quistion"
      ok-title="Create"
      cancel-variant="outline-secondary"
      size="xl"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <validation-observer ref="questionInfoRules" tag="form">
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">question Details</h5>
            </b-col>
            <b-col md="5">
              <b-form-group label="title" label-for="name">
                <validation-provider
                  #default="{ errors }"
                  name="title"
                  rules="required|min:3"
                >
                  <b-form-input
                    id="title"
                    v-model="question.title"
                    :state="errors.length > 0 ? false : null"
                    placeholder="question title"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group label="question type" label-for="questiontype">
                <validation-provider
                  #default="{ errors }"
                  name="question type"
                  rules="required"
                >
                  <b-form-select
                    id="questiontype"
                    :state="errors.length > 0 ? false : null"
                    v-model="question.type"
                    :options="options"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group label="grad" label-for="grad">
                <validation-provider
                  #default="{ errors }"
                  name="grad"
                  rules="required|numeric"
                >
                  <b-form-input
                    id="title"
                    v-model="question.grad"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                label="question Cover Image"
                label-for="CorseCoverImage"
              >
                <validation-provider
                  #default="{ errors }"
                  name="CorseCoverImage"
                >
                  <b-form-file
                    :id="'CorseCoverImage' + index"
                    accept="image/*"
                    v-model="question.imageCover"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group label="hint" label-for="hint">
                <validation-provider #default="{ errors }" name="hint">
                  <b-form-textarea
                    id="hint"
                    placeholder="hint"
                    v-model="question.hint"
                    :state="errors.length > 0 ? false : null"
                    rows="1"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <addAnswerSection :answers="question.answers" />
            </b-col>
          </b-row>
        </validation-observer>
      </form>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BFormGroup,
  BFormInput,
  BModal,
  VBModal,
  BListGroup,
  BListGroupItem,
  BRow,
  BCol,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import questionsApi from "@/apiServices/questionsApi";
import { required, email, max_value, numeric } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import addAnswerSection from "@/views/answers pages/components/add answers section new.vue";
import imagesApi from "@/apiServices/imagesApi";

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BListGroup,
    BListGroupItem,
    BModal,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    addAnswerSection,
  },
  directives: {
    Ripple,
  },
  data: () => ({
    required,
    email,
    max_value,
    numeric,
    name: "",
    nameState: null,
    options: ["single_choice", "multi_choice", "true_false"],
    submittedNames: [],
    question: {
      title: "",
      description: "",
      type: "",
      imageCover: null,
      answers: [],
      grad: 0,
    },
  }),
  methods: {
    createquestions(questionFormData) {
      questionsApi.createquestion(questionFormData).then((response) => {
        if (response.data) {
          //   this.question = response.data;
          this.question = {
            title: "",
            description: "",
            type: "",
            imageCover: null,
            answers: [],
            grad: 0,
          };
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "question Created ",
              icon: "EditIcon",
              variant: "success",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      });
    },

    createquestionValidation() {
      return new Promise((resolve, reject) => {
        this.$refs.questionInfoRules.validate().then((success) => {
          if (success) {
            const questionFormData = new FormData();
            // this.question.answers.forEach((item) => {
            //   item = item._id;
            // });
            this.question.answers = this.question.answers.map((a) => a._id);
            this.createquestions(this.question);
            this.question = {};
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    resetModal() {
      this.name = "";
      this.nameState = null;
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      // Trigger submit handler
      if (this.question.imageCover instanceof File) {
        const imageFormData = new FormData();
        imageFormData.append("image", this.question.imageCover);
        imagesApi.uploadImage(imageFormData).then((response) => {
          console.log(response);
          this.question.imageCover = response;
          this.createquestionValidation();
        });
      } else {
        delete this.question.imageCover;
        this.createquestionValidation();
      }
    },
    removeItem(index) {
      this.questions?.splice(index, 1);
      // this.trTrimHeight(this.$refs.row[0].offsetHeight);
    },
    handleSubmit() {
      // Exit when the form isn' t valid
      if (!this.checkFormValidity()) {
        return;
      }
      // Push the name to submitted names
      this.submittedNames.push(this.name);
      // Hide the modal manually
      this.$nextTick(() => {
        this.$refs["my-modal"].toggle("#toggle-btn");
      });
    },
  },
};
</script>
