import axiosApi from "../axios";
export default {
  async getQuize(question) {
    return await axiosApi
      .get(`/quizies/${question}`)
      .then((res) => {
        return res.data;
      })
      .catch((e) => {
        console.log(
          JSON.stringify(e.response?.data?.errors) + "this is an error "
        );
        return e.response?.data?.errors;
      });
  },
  async getquizies(pramas) {
    return await axiosApi
      .get(`/quizies`, { params: pramas })
      .then((res) => {
        console.log(res.data + "this is data ");
        return res.data;
      })
      .catch((e) => {
        console.log(
          JSON.stringify(e.response?.data?.errors) + "this is an error "
        );
        return e.response?.data?.errors;
      });
  },

  async updatequize(quize) {
    return await axiosApi
      .put(`/quizies/${quize._id}`, quize)
      .then((res) => {
        console.log(res.data + "this is data ");
        return res;
      })
      .catch((e) => {
        console.log(
          JSON.stringify(e.response?.data?.errors) + "this is an error "
        );
        return e.response?.data?.errors;
      });
  },
  async createquize(quize) {
    return await axiosApi
      .post(`/quizies`, quize)
      .then((res) => {
        console.log(res.data + "this is data ");
        return res;
      })
      .catch((e) => {
        console.log(
          JSON.stringify(e.response?.data?.errors) + "this is an error "
        );
        return e.response?.data?.errors;
      });
  },
  async deletequize(pramas) {
    return await axiosApi
      .delete(`/quizies/${pramas.id}`)
      .then((res) => {
        return res.status;
      })
      .catch((e) => {
        console.log(
          JSON.stringify(e.response?.data?.errors) + "this is an error "
        );
        return e.response;
      });
  },
};
