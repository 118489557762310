<template>
  <div>
    <!-- modal trigger button -->

    <b-row>
      <b-col>
        <b-dropdown-item @click="showModal">
          <feather-icon icon="Edit2Icon" class="mr-50" />
          <span>Edit</span>
        </b-dropdown-item>
      </b-col>
    </b-row>

    <!-- <b-button
        id="show-btn"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="outline-primary"
      >
        delete
      </b-button> -->

    <!-- modal -->
    <b-modal ref="my-modal" modal-class="modal-info" hide-footer title="update question" size="xl">
      <b-overlay :show="show1" rounded="sm">

        <form ref="form" @submit.stop.prevent="handleSubmit">
          <validation-observer ref="questionInfoRules" tag="form">
            <b-row>
              <b-col cols="12" class="mb-2">
                <h5 class="mb-0">question Details</h5>
                <small class="text-muted"> Enter the question Details. </small>
              </b-col>
              <b-col md="6">
                <b-form-group label="title" label-for="name">
                  <validation-provider #default="{ errors }" name="title" rules="required|min:3">
                    <b-form-input id="title" v-model="question.title" :state="errors.length > 0 ? false : null"
                      placeholder="couse name" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="question type" label-for="questiontype">
                  <validation-provider #default="{ errors }" name="question type" rules="required">
                    <b-form-select id="questiontype" :state="errors.length > 0 ? false : null" v-model="question.type"
                      :options="options" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="question Cover Image" label-for="CorseCoverImage">
                  <validation-provider #default="{ errors }" name="CorseCoverImage" rules="required">
                    <b-form-file id="CorseCoverImage" accept="image/*" v-model="question.imageCover" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="hint" label-for="hint">
                  <validation-provider #default="{ errors }" name="hint">
                    <b-form-textarea id="hint" placeholder="hint" v-model="question.hint"
                      :state="errors.length > 0 ? false : null" rows="8" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <addAnswerSection :answers="question.answers" />
              </b-col>
            </b-row>
          </validation-observer>
        </form>
        <b-row variant="info">
          <b-col md="4"> </b-col>
          <b-col md="3">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="" variant="outline-secondary" block
              @click="hideModal">
              No
            </b-button>
          </b-col>
          <b-col md="3">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="" variant="outline-info" block
              @click="toggleModal">
              <feather-icon icon="Edit2Icon" class="mr-50" />
              <span class="mr-50 text-info">update</span>
            </b-button>
          </b-col>
        </b-row>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BFormGroup,
  BFormInput,
  BModal,
  VBModal,
  BListGroup,
  BListGroupItem,
  BRow,
  BCol,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import questionsApi from "@/apiServices/questionsApi";
import { required, email, max_value, numeric } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import addAnswerSection from "@/views/answers pages/components/add answers section new.vue";
import imagesApi from "@/apiServices/imagesApi";

export default {
  props: ["question"],

  components: {
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BListGroup,
    BListGroupItem,
    BModal,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    addAnswerSection,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      email,
      max_value,
      numeric,
      options: ["single_choice", "multi_choice", "true_false"],
      show1: false,

      prams: {
        id: "",
      },
    };
  },
  created() {
    console.log(this.question);
    // this.question.imageCover = null;
  },
  methods: {
    updatequestion() {
      this.show1 = true;

      questionsApi.updatequestion(this.question).then((response) => {
        if (response.data) {
          // this.course = response.data;
          // console.log(response.data);
          this.$emit("updatequestionFromlist", this.id);

          this.$toast({
            component: ToastificationContent,
            props: {
              title: "question updated",
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.show1 = false;
          this.$refs["my-modal"].toggle("#toggle-btn");

        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
          this.show1 = false;
          this.$refs["my-modal"].toggle("#toggle-btn");

        }
      });
    },
    showModal() {
      console.log(this.id + "this id");

      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      if (this.question.imageCover instanceof File) {
        const imageFormData = new FormData();
        imageFormData.append("image", this.question.imageCover);
        imagesApi.uploadImage(imageFormData).then((response) => {
          console.log(response);
          this.question.imageCover = response;
          this.updatequestion();
        });
      } else {
        delete this.question.imageCover;
        this.updatequestion();
      }
    },
  },
};
</script>
