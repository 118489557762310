var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}},{name:"b-modal",rawName:"v-b-modal.modal-quistion-add",modifiers:{"modal-quistion-add":true}}],attrs:{"id":"toggle-btn","variant":"outline-primary"}},[_vm._v(" + ")]),_c('b-modal',{ref:"modal-quistion-add",attrs:{"id":"modal-quistion-add","title":"Add new quistion","ok-title":"Create","cancel-variant":"outline-secondary","size":"xl"},on:{"show":_vm.resetModal,"hidden":_vm.resetModal,"ok":_vm.handleOk}},[_c('form',{ref:"form",on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleSubmit($event)}}},[_c('validation-observer',{ref:"questionInfoRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v("question Details")])]),_c('b-col',{attrs:{"md":"5"}},[_c('b-form-group',{attrs:{"label":"title","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"title","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"title","state":errors.length > 0 ? false : null,"placeholder":"question title"},model:{value:(_vm.question.title),callback:function ($$v) {_vm.$set(_vm.question, "title", $$v)},expression:"question.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"2"}},[_c('b-form-group',{attrs:{"label":"question type","label-for":"questiontype"}},[_c('validation-provider',{attrs:{"name":"question type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"questiontype","state":errors.length > 0 ? false : null,"options":_vm.options},model:{value:(_vm.question.type),callback:function ($$v) {_vm.$set(_vm.question, "type", $$v)},expression:"question.type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"2"}},[_c('b-form-group',{attrs:{"label":"grad","label-for":"grad"}},[_c('validation-provider',{attrs:{"name":"grad","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"title","state":errors.length > 0 ? false : null},model:{value:(_vm.question.grad),callback:function ($$v) {_vm.$set(_vm.question, "grad", $$v)},expression:"question.grad"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"question Cover Image","label-for":"CorseCoverImage"}},[_c('validation-provider',{attrs:{"name":"CorseCoverImage"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"id":'CorseCoverImage' + _vm.index,"accept":"image/*"},model:{value:(_vm.question.imageCover),callback:function ($$v) {_vm.$set(_vm.question, "imageCover", $$v)},expression:"question.imageCover"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"hint","label-for":"hint"}},[_c('validation-provider',{attrs:{"name":"hint"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"hint","placeholder":"hint","state":errors.length > 0 ? false : null,"rows":"1"},model:{value:(_vm.question.hint),callback:function ($$v) {_vm.$set(_vm.question, "hint", $$v)},expression:"question.hint"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('addAnswerSection',{attrs:{"answers":_vm.question.answers}})],1)],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }