<template>
  <b-card-code title="All questions " no-body>
    <b-card-body>
      <div class="d-flex justify-content-between flex-wrap">
        <!-- sorting  -->

        <!-- filter -->
        <b-form-group class="mb-0">
          <b-input-group size="sm">
            <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        <addNewquestion />

        <!-- <addNewLeson /> -->
      </div>
      <b-row>
        <b-col>
          <b-form-checkbox-group id="checkbox-group-2" v-model="selectedQuestions" class="demo-inline-spacing">
            <b-row v-for="(question, index) in questions" :id="question.id" :key="question.id">
              <b-col md="12">
                <b-form-checkbox :value="question">
                  <b-avatar :src="question.imageCover" />
                  {{ question.title }}
                </b-form-checkbox>
              </b-col>
            </b-row>
          </b-form-checkbox-group>
        </b-col>
      </b-row>
    </b-card-body>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <!-- pagination -->
      <div>
        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="prams.limit" first-number last-number
          prev-class="prev-item" next-class="next-item" class="mb-0">
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>
    </b-card-body>
    <b-col md="12">
      <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" class="mt-0 mt-md-2 w-100"
        @click="addQuestions()">
        <feather-icon icon="PlusIcon" class="mr-25" />
        <span>Add The Questions</span>
      </b-button>
    </b-col>

    <template #code>
      <!-- {{ codeKitchenSink }} -->
    </template>
  </b-card-code>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BTable,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCardBody,
} from "bootstrap-vue";
// import { codeKitchenSink } from "./code";
import questionsApi from "@/apiServices/questionsApi";
import deletequestion from "@/views/questions pages/delete.vue";
import editquestion from "@/views/questions pages/edit.vue";
import addNewLeson from "@/views/questions pages/add.vue";
import addNewquestion from "@/views/questions pages/components/addnew.vue";

export default {
  components: {
    BCardCode,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    deletequestion,
    addNewLeson,
    editquestion,
    addNewquestion,
  },
  data() {
    return {
      selectedQuestions: [],
      prams: {
        sort: "-createdAt",
        page: 1,
        limit: 100,
        title: "",
      },
      options: ["single_choice", "multi_choice", "true_false"],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      filter: "",
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },

      questions: [],
    };
  },
  created() {
    // this.getquestions();
  },
  computed: {},
  mounted() {
    // this.totalRows = this.items.length;
  },
  watch: {
    currentPage() {
      this.prams.page = this.currentPage;

      this.getquestions();
    },
    filter() {
      this.prams.page = this.currentPage;
      this.prams.title = this.filter;

      this.getquestions();
    },
  },
  methods: {
    addQuestions() {
      console.log("this" + this.selectedQuestions);
      try {
        this.$emit("addQuestions", this.selectedQuestions);
      } catch (error) {
      } finally {
        this.selectedQuestions = [];
      }
    },
    getquestions() {
      if (this.filter == "") {
        delete this.prams.title;
      }
      questionsApi.getquestions(this.prams).then((response) => {
        this.questions = [];
        if (response.data) {
          this.questions = response.data;
          this.currentPage = response.paginationResult?.currentPage;
          this.totalRows =
            response.paginationResult?.numberOfPages * this.prams.limit;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "get All questions",
              icon: "EditIcon",
              variant: "success",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      });
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      //   this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
