<template>
  <div>
    <b-button
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      v-b-toggle.sidebar-1
      variant="outline-primary mr-50"
    >
      choose Questions
    </b-button>

    <b-sidebar id="sidebar-1" shadow bg-variant="white" backdrop>
      <QuestionsList @addQuestions="addQuestions" />
    </b-sidebar>
  </div>
</template>

<script>
import { BButton, BSidebar, VBToggle, BImg } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import QuestionsList from "@/views/questions pages/components/listCheckBox.vue";

export default {
  components: {
    BButton,
    BSidebar,
    BImg,
    QuestionsList,
  },
  //   props: ["selectedQuestions"],
  directives: {
    "b-toggle": VBToggle,
    Ripple,
  },
  methods: {
    addQuestions(selectedQuestions) {
      console.log("this" + selectedQuestions);
      this.$emit("addQuestions", selectedQuestions);
    },
  },
  data() {
    return {
      selectedQuestions: [],
    };
  },
};
</script>
