<template>
  <b-card-code title="Add Quize" no-body name="Add Quize">
    <form ref="form" @submit.stop.prevent="handleSubmit">
      <validation-observer ref="quizeInfoRules" tag="form">
        <b-row>
          <b-col cols="12">
            <h5 class="mb">quize Details</h5>
            <small class="text-muted"> Enter the quize Details. </small>
          </b-col>
          <b-col md="6">
            <b-form-group label="title" label-for="name">
              <validation-provider
                #default="{ errors }"
                name="title"
                rules="required|min:3"
              >
                <b-form-input
                  id="title"
                  v-model="quize.title"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Quize title "
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="grad" label-for="name">
              <validation-provider
                #default="{ errors }"
                name="grad"
                rules="required|numeric"
              >
                <b-form-input
                  id="grad"
                  v-model="quize.grad"
                  :state="errors.length > 0 ? false : null"
                  placeholder="grade"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="timer" label-for="name">
              <validation-provider
                #default="{ errors }"
                name="timer"
                rules="required|numeric"
              >
                <b-form-input
                  id="timer"
                  v-model="quize.timer"
                  :state="errors.length > 0 ? false : null"
                  placeholder="timer"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-row>
            <b-col>
              <questionsSidebar @addQuestions="addQuestions" />
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <addQuestionsRepiter :questions="quize.questions" />
            </b-col>
          </b-row>
        </b-row>
        <b-row>
          <b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="mt-25 w-100"
              @click="handleOk()"
            >
              <feather-icon icon="PlusIcon" class="mr-25" />
              <span>Add New Quize</span>
            </b-button>
          </b-col>
        </b-row>
      </validation-observer>
    </form>
  </b-card-code>
</template>

<script>
import {
  BButton,
  BFormGroup,
  BFormInput,
  BModal,
  VBModal,
  BListGroup,
  BListGroupItem,
  BRow,
  BCol,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import quizesApi from "@/apiServices/quizesApi";
import { required, email, max_value, numeric } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import addQuestionsRepiter from "@/views/questions pages/components/repiter.vue";
import questionsSidebar from "@/views/questions pages/components/questionsSidebar.vue";
export default {
  components: {
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BListGroup,
    BListGroupItem,
    BModal,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    addQuestionsRepiter,
    questionsSidebar,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data: () => ({
    required,
    email,
    max_value,
    numeric,
    name: "",
    nameState: null,
    options: ["video", "pdf", "powerpoint"],
    submittedNames: [],
    quize: {
      title: "",
      description: "",
      grad: 0,
      questions: [],
    },
  }),
  methods: {
    addQuestions(questions) {
      console.log("sadasdasdad");
      questions.forEach((element) => {
        this.quize.questions.push(element);
      });
    },
    createquizes(quizeFormData) {
      quizesApi.createquize(quizeFormData).then((response) => {
        if (response.data) {
          //   this.quize = response.data;
          console.log(response.data);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "quize Created complite adding the Questions  and answers",
              icon: "EditIcon",
              variant: "success",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      });
    },

    createquizeValidation() {
      return new Promise((resolve, reject) => {
        this.$refs.quizeInfoRules.validate().then((success) => {
          if (success) {
            // const quizeFormData = new FormData();
            // Object.entries(this.quize).forEach(([key, value]) => {
            //   quizeFormData.append(key, value);
            // });
            let temp = [];
            this.quize.questions.forEach((element) => {
              temp.push({
                questionId: element._id,
                marks: element.grad,
              });
            });
            this.quize.questions = temp;
            this.createquizes(this.quize);
            this.$refs["my-modal"].toggle("#toggle-btn");
            this.quize = {};
            resolve(true);
          } else {
            console.log("reasdsadasd");
            reject();
          }
        });
      });
    },
    resetModal() {
      this.name = "";
      this.nameState = null;
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      // Trigger submit handler
      this.createquizeValidation();
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      // Push the name to submitted names
      this.submittedNames.push(this.name);
      // Hide the modal manually
      this.$nextTick(() => {
        this.$refs["my-modal"].toggle("#toggle-btn");
      });
    },
  },
};
</script>
